<template>
    <div class="row tab-content mt-2" id="tab4">
        <div class="col-12 col-md-4" v-if="!store.loading && Object.keys(store.LiveFollowers).length && store.LiveFollowers.content && store.LiveFollowers.content.length"> 
            <div class="masterPips">
                <div class="leftPips">
                    <div class="card-body pointer bg-white mb-3" v-for="item,index in store.LiveFollowers.content" :key="index" @click="changeFollowers(item)" :class="[{'active' : followerData.brokerAccountId == item.brokerAccountId}]">
                        <div class="d-flex align-items-start position-relative">
                            <span class="me-3">
                                <v-lazy-image width="50" height="50" class="rounded-circle" :src="static_vars.imagesURL+'webservices/Image.ashx?type=user&size=XS&id='+item.zuluAccountId+'&ignore=false'" :alt="$t('copy_strategy.alt_title')" :title="$t('copy_strategy.alt_title')" />
                            </span>
                            <div class="flex-shrink-0">
                                <h6 class="medium mb-0">{{item.name}}</h6>
                                <p class="my-1 gray f-11">{{item.profitUsd > 0 ? '' : '-'}}${{Math.abs(item.profitUsd)}} <!-- <span class="ms-2 border-start ps-2">{{item.profitPips}}</span> --></p>
                                <span v-if="appMode == 'COBRANDED' && item.brokerId">
                                    <v-lazy-image class="d-block" width="88px" src="/assets/images/logo.svg" alt="ZuluTrade" title="ZuluTrade" />
                                </span>
                                <span v-else-if="appMode == 'WEBSITE' && item.brokerId">
                                    <v-lazy-image :src="static_vars.imagesURL+'webservices/Image.ashx?type=broker&size=S&id='+item.brokerId+'&flavor=ZuluTrade'" :alt="item.name" :title="item.name" />
                                </span>
                                <span class="otherBroker" v-else> {{$t('leader_profile.otherBroker')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <Pagination :pageNo="page" :totalRecords="store.LiveFollowers.totalElements" :perPage="'10'" :type="'Real-Investors'" v-if="store.LiveFollowers.totalElements > 10"></Pagination>
            </div>
        <Nodata v-else></Nodata>
        <div class="col-12 col-md-8" v-if="Object.keys(followerData).length">
            <div class="bg-white boxed">
                <h3 class="bold mb-0">{{followerData.name}}</h3>
                <span v-if="appMode == 'COBRANDED' && followerData.brokerId">
                    <v-lazy-image class="d-block" width="88px" src="/assets/images/logo.svg" alt="ZuluTrade" title="ZuluTrade" />
                </span>
                <span v-else-if="appMode == 'WEBSITE' && followerData.brokerId">
                    <v-lazy-image :src="static_vars.imagesURL+'webservices/Image.ashx?type=broker&size=S&id='+followerData.brokerId+'&flavor=ZuluTrade'"  :alt="followerData.name" :title="followerData.name" />
                </span>
                <span class="otherBroker" v-else> {{$t('leader_profile.otherBroker')}}</span>
                <div class="d-flex align-items-center border-bottom border-top py-1 my-2">
                    <p class="medium uppercase mb-0 me-4">{{$t('leader_profile.profit')}}: {{followerData.profitUsd > 0 ? '' : '-'}}${{Math.abs(followerData.profitUsd)}}</p>
                    <!-- <p class="medium uppercase mb-0">PIPS: {{followerData.profitPips}} pips</p> -->
                </div>
                <div class="d-flex align-items-center overflow-auto">
                    <p class="d-flex align-items-center medium uppercase mb-0" v-if="store.traderDetail.trader.stats.profile && store.traderDetail.trader.stats.profile.name"><span class="me-2 blueColor"></span> {{store.traderDetail.trader.stats.profile.name}}</p>
                    <p class="italic uppercase mb-0 mx-3">VS</p>
                    <p class="d-flex align-items-center medium uppercase mb-0"><span class="me-2 secondaryColor"></span> {{followerData.name}}</p>
                </div>
                <div class="chartBlock position-relative">
                    <Loader :classname="'innerLoader'" v-if="store.singleLoading"></Loader>
                    <MasterPips ref="pipsChart" />
                </div>
                <div class="hasFollow">
                        <ul class="zuluTabs d-flex align-items-center mb-3 border-bottom">
                        <li :class="[{'active' : type == 'follwers'}]"><a class="medium pb-3 f-13" href="javascript:void(0)" @click="type = 'follwers'">{{$t('leader_profile.copying')}}</a></li>
                        <li :class="[{'active' : type == 'has_followed'}]"><a class="medium pb-3 f-13" href="javascript:void(0)" @click="type = 'has_followed'">{{$t('leader_profile.hasCopied')}}</a></li>
                    </ul>
                    <ul class="hasList d-flex align-items-start flex-wrap" v-if="type == 'follwers' && Object.keys(store.followersFollowingList).length && store.followersFollowingList.content && store.followersFollowingList.content.length">
                        <li v-for="item,index in store.followersFollowingList.content" :key="index" @click="changeUser(item)">
                            <span class="d-block mb-1"><v-lazy-image class="rounded-circle" :src="static_vars.imagesURL+'webservices/Image.ashx?type=user&size=S&id='+item.zuluAccountId+'&ignore=false'" :alt="item.name" :title="item.name" /></span>
                            <a href="javascript:void(0)" class="link f-12">{{item.name}}</a>
                        </li>
                    </ul>
                     <ul class="hasList d-flex align-items-start flex-wrap" v-if="type == 'has_followed' && Object.keys(store.followersHasFollowedList).length && store.followersHasFollowedList.content && store.followersHasFollowedList.content.length">
                        <li v-for="item,index in store.followersHasFollowedList.content" :key="index" @click="changeUser(item)">
                            <span class="d-block mb-1"><v-lazy-image class="rounded-circle" :src="static_vars.imagesURL+'webservices/Image.ashx?type=user&size=S&id='+item.zuluAccountId+'&ignore=false'" :alt="item.name" :title="item.name" /></span>
                            <a href="javascript:void(0)" class="link f-12">{{item.name}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MasterPips from "@/components/charts/masterpips"
import { myStore } from "@/store/pinia-store";
import Pagination from "@/components/shared/pagination.vue"
import $ from "jquery";
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data(){
        return {
            page : 1,
            type : 'follwers',
            followerData : {},
            appMode : process.env.VUE_APP_MODE,
        }
    },
    components: {
        MasterPips,Pagination
    },
     watch: { 
      '$parent.followerInfo'() {
            if(Object.keys(this.$parent.followerInfo).length){
                this.changeFollowers(this.$parent.followerInfo)
            }
        },
        "store.LiveFollowers"(){
            if(Object.keys(this.store.LiveFollowers).length && this.store.LiveFollowers.content && this.store.LiveFollowers.content.length){
                this.changeFollowers(this.store.LiveFollowers.content[0])
            }
        }
    },
    methods:{
        changeUser(item){
            if(item.id){
                this.$parent.tab = 1
                this.$parent.followerInfo = {}
                let path =  '/trader/'+item.id+'/trading?t=10000&m=1'
                this.$router.replace(path)
            }
        },
        changeFollowers(item){
            this.followerData = item
            this.callFollowersFollowingList(item.brokerAccountId)
            this.callFollowersHasFollowedList(item.brokerAccountId)
            this.chartData(item.brokerAccountId)
        },
        getProviderId(){
            if(this.store.userTradingAccountsList.length){
                if(this.store.allTypeAccount == 'ALL_LIVE'){
                    let LiveAccs = this.store.userTradingAccountsList.filter(i=>(i.demo === false && i.isLeader && i.providerStatus=='APPROVED' && i.connectivityStatus == 'Connected'));
                    if(LiveAccs?.length && LiveAccs?.[0]?.providerId){
                        return LiveAccs?.[0]?.providerId
                    }
                }else if(this.store.allTypeAccount == 'ALL_DEMO'){
                    let demoAccs = this.store.userTradingAccountsList.filter(i=>(i.demo === true && i.isLeader && i.providerStatus=='APPROVED' && i.connectivityStatus == 'Connected'));
                    if(demoAccs?.length && demoAccs?.[0]?.providerId){
                        return demoAccs?.[0]?.providerId
                    }
                }   
            }
        },
        callLiveFollowersList(){
            if(this.$route.name != 'dashboard_portfolio'){
                this.store.getLiveFollowersList({},false,this.$route.params.id,parseInt(this.page)-1)
            }else{
                this.store.getLiveFollowersList({},false,(this.store.allTypeAccount) ? this.getProviderId() : this.store.userSelectedAccount.providerId,parseInt(this.page)-1)
            }
        },
        callFollowersFollowingList(id){
            let ID = ''
            if(id){ ID = id
            }else{
                ID = this.$parent.followerInfo.brokerAccountId
            }
            this.store.getFollowersFollowingList({},false,ID)
        },
        callFollowersHasFollowedList(id){
            let ID = ''
            if(id){ ID = id
            }else{
                ID = this.$parent.followerInfo.brokerAccountId
            }
            this.store.getFollowersHasFollowedList({},false,ID)
        },
        chartData(id){
            if(this.$route.name != 'dashboard_portfolio'){
                this.store.callFollowersChartData({},true,this.$route.params.id,id).then( () =>{
                    if(this.store.followersChartData.length){
                        this.$refs.pipsChart.masterpips()
                    }
                })
            }else{
                this.store.callFollowersChartData({},true,(this.store.allTypeAccount) ? this.getProviderId() : this.store.userSelectedAccount.providerId,id).then( () =>{
                    if(this.store.followersChartData.length){
                        this.$refs.pipsChart.masterpips()
                    }
                })
            }
        }
    },
    created(){
        this.callLiveFollowersList()
        if(Object.keys(this.$parent.followerInfo).length){
            this.callFollowersFollowingList()
            this.callFollowersHasFollowedList()
            this.followerData = this.$parent.followerInfo
        }
    },
    updated() {
        this.$parent.divHeigh = $('.div-height').height() + 30;
    },
};
</script>