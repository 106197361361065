<template>
  <div class="position-relative">
    <Loader :classname="'innerLoader'" v-if="store.singleLoading"></Loader>
    <Performance  v-if="$parent.charttab == 1" />
    <Trading  v-if="$parent.charttab == 2" />
    <Drawdown  v-if="$parent.charttab == 3" />
    <Slippage  v-if="$parent.charttab == 4" />
  </div>
</template>
<script>
  import { myStore } from "@/store/pinia-store";
  import Performance from "@/components/charts/trader-performance";
  import Trading from "@/components/charts/trader-trading";
  import Drawdown from "@/components/charts/trader-drawdown";
  import Slippage from "@/components/charts/trader-slippage";
  import $ from "jquery";
  export default {
    setup() {
      const store = myStore();
      return { store };
    },
    data() {
      return {};
    },
    components: {
      Performance,
      Trading,
      Drawdown,
      Slippage,
    },
    methods: {
    },
    updated() {
      this.$parent.divHeigh = $('.div-height').height() + 30;
    },
  };
</script>
