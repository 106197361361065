<template>
    <!-- <Loader v-if="store.singleLoading"></Loader> -->
    <div id="masterpips" class="masterpips" v-show="store.followersChartData.length"></div>
    <Nodata v-if="!store.singleLoading && !store.followersChartData.length"></Nodata>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import { myStore } from "@/store/pinia-store";
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data() {
        return {
            pipsJson : {
                0 : 'Copiers',
                1 : 'Leader'
            }
        }
    },
    methods: {
        masterpips(){
            let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'masterpips');
            d?.dispose();
            var root = am5.Root.new("masterpips");
            root.setThemes([
                am5themes_Animated.new(root),
                am5themes_Responsive.new(root)
            ]);
            // root.dateFormatter.setAll({
            //     dateFormat: "yyyy",
            //     dateFields: ["valueX"]
            // });
            root.numberFormatter.setAll({
                numberFormat: "#.##",
            });
            root.dateFormatter.set("dateFormat", "yyyy-MM-dd");
            
            // Create chart
            // https://www.amcharts.com/docs/v5/charts/xy-chart/
            var chart = root.container.children.push(am5xy.XYChart.new(root, {
                focusable: false,
                // panX: false,
                // panY: false,
                // wheelX: "panX",
                // wheelY: "zoomX",
                paddingRight: 0,
                paddingLeft : 0,
            }));

            chart.get("colors").set("colors", [
                am5.color(0xff7200),
                am5.color(0x4572a7),
            ]);

            // Add cursor
            // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
            var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
              behavior: "zoomX"
            }));
            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);
            var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
              maxDeviation: 0,
              baseInterval: {
                timeUnit: "day",
                count: 1
              },
              renderer: am5xy.AxisRendererX.new(root, {}),
            }));

            var label = am5.Label.new(root, {
                text: "[#666] Date [/]",
                x: am5.p50,
                centerY: am5.p50,
                fontSize: 12,
            })
            xAxis.children.push( label );

            var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
              extraMin: 0.2,
              renderer: am5xy.AxisRendererY.new(root, {})
            }));
            xAxis.get("renderer").grid.template.set("forceHidden", true);
            yAxis.get("renderer").grid.template.set("forceHidden", true);
            var label1 = am5.Label.new(root, {
                rotation: -90,
                text: "[#666] Money [/]",
                y: am5.p50,
                centerX: am5.p50,
                fontSize: 12,
            })
            yAxis.children.unshift( label1 );
             let yRenderer = yAxis.get("renderer");
            yRenderer.grid.template.setAll({
                stroke: am5.color(0x666666),
                strokeWidth: 1
            });
            var legend = chart.bottomAxesContainer.children.push(am5.Legend.new(root, {
                x: am5.percent(50),
                centerX: am5.percent(50),
                layout: root.horizontalLayout,
                useDefaultMarker: true,
                // paddingTop: 15,
            }));
            
            legend.labels.template.setAll({
                fontSize: 12,
                fontWeight: "600"
            });
            legend.markerRectangles.template.setAll({
                cornerRadiusTL: 20,
                cornerRadiusTR: 20,
                cornerRadiusBL: 20,
                cornerRadiusBR: 20
            });
            legend.markers.template.setAll({
                width: 13,
                height: 13
            });
            this.store.followersChartData.forEach((val,index) => {
                if(val.data && val.data.length){
                    var series = chart.series.push(am5xy.SmoothedXLineSeries.new(root, {
                    legendLabelText: this.pipsJson[index],
                      name: "Series",
                      xAxis: xAxis,
                      yAxis: yAxis,
                      valueYField: "y",
                      valueXField: "x",
                      tooltip: am5.Tooltip.new(root, {
                        labelText:(index == 0) ? "[bold]Date[/]: {valueX.formatDate('dd MMM yyyy')} \n[bold]Copiers[/]: ${valueY} " : "[bold]Date[/]: {valueX.formatDate('dd MMM yyyy')} \n[bold]Leader[/]: ${valueY} ",
                        scale: 0.7,
                      })
                    }));
                    series.strokes.template.setAll({
                        strokeWidth: 2
                    });
                    series.data.setAll(val.data);
                    //legend.data.unshift(series);
                    
                    series.hide();
                    series.appear(1000);
                     }
                    
            })
             xAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#666666")),
                fontSize: 12,
            });
            yAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#ffa35a")),
                fontSize: 12,
            });
            legend.data.setAll(chart.series.values);
            chart.appear(1000, 100);
        },
        getFollowersChartData(id){
            let ID = ''
            if(id){
                ID = id
            }else{
                ID = this.$parent.followerData.brokerAccountId
            }
            this.store.callFollowersChartData({},true,this.$route.params.id,ID).then( () =>{
                if(this.store.followersChartData.length){
                    this.masterpips()
                }
            })
        }
    },
    mounted() {
        //this.getFollowersChartData()
    },
}
</script>
<style>
    .masterpips{
        height: 500px;
        width: 100%;
    }
</style>